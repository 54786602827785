import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, exhaustMap, catchError, finalize } from 'rxjs/operators';

import { AuthActions } from '../_actions/auth.actions';
import { AuthService } from '../../_services/auth.service';
import { AppState } from '..';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  LayoutUtilsServices,
  MessageType,
} from '../../_services/layout-utils.services';
import { LayoutActions } from '../_actions/layout.actions';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginAuth),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._authService.login(action.username, action.password).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Bienvenido',
              type: MessageType.Susses,
            });
            this.router.navigate(['/']);
            return AuthActions.loginAuthSuccess({
              token: res.access_token,
              status: true,
            });
          }),
          catchError(({ error }) => {
            this._layoutUtilsServices.showNotification({
              message: 'Usuario o contraseña incorrectos',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginAuthSuccess),
        map(({ token }) => {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('token', token);
            this.store.dispatch(AuthActions.loadUserAuth());
          }
        })
      ),
    { dispatch: false }
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserAuth),
      exhaustMap((action) => {
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._authService.me().pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((user) => {
            this._authService.setPermissions(user);
            return AuthActions.loadUserAuthSuccess({ user })
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutAuth),
        map(() => {
          this._authService.resetPermissions();
          if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('token');
          }

          this.router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );

  forgot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotAuth),
      exhaustMap((action) => {
        console.log(action);
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._authService.forgot(action.email).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((user) => {
            return AuthActions.forgotAuthSuccess();
          }),
          catchError(({ error }) => {
            this._layoutUtilsServices.showNotification({
              message: 'Usuario incorrectos',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.resetPasswordAuth),
      exhaustMap((action) => {
        console.log(action);
        this.store.dispatch(LayoutActions.spinnerTrigger({ show: true }));
        return this._authService.reset(action.hash, action.password).pipe(
          finalize(() =>
            this.store.dispatch(LayoutActions.spinnerTrigger({ show: false }))
          ),
          map((res) => {
            this._layoutUtilsServices.showNotification({
              message: 'Bienvenido',
              type: MessageType.Susses,
            });
            this.router.navigate(['/sales/dasboard']);
            return AuthActions.loginAuthSuccess({
              token: res.access_token,
              status: true,
            });
          }),
          catchError(({ error }) => {
            this._layoutUtilsServices.showNotification({
              message: 'Usuario incorrectos',
              type: MessageType.Danger,
            });
            return EMPTY;
          })
        );
      })
    )
  );

  init$ = createEffect(() => {
    let observableResult = of({ type: 'INI_ACTION' });
    if (isPlatformBrowser(this.platformId)) {
      const userToken = localStorage.getItem('token');
      if (userToken) {
        observableResult = of(
          AuthActions.loginAuthSuccess({
            token: userToken,
            status: true,
          })
        );
      }
    }
    return observableResult;
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private _authService: AuthService,
    private _layoutUtilsServices: LayoutUtilsServices,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}
}
